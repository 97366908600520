<template>
  <div class="contactus">
    <HeadMobile></HeadMobile>
    <div class="contactus-titles">
      <p class="contactus-title">{{ $store.state.staticName.company }}</p>
    </div>
    <div class="contactus-main">
      <div class="map-box">
        <el-image
          style="width: 100%"
          :src="require('../../assets/map.png')"
          :preview-src-list="[require('../../assets/map.png')]"
        >
        </el-image>
        <!--        <MyBaiduMap></MyBaiduMap>-->
      </div>
      <div class="contactus-main-box">
        <div class="contactus-main-box-left">
          <div class="msg msg1">
            {{ $store.state.staticName.phone }}：400-689-8628
          </div>
          <div class="msg">{{ $store.state.staticName.company_address }}</div>
          <div class="msg">
            {{ $store.state.staticName.email }}：wuyuekeji201781@sina.com
          </div>
          <div class="msg">
            {{ $store.state.staticName.WeChat_Official_Account }}：午跃钛科技
          </div>
          <div class="msg">QQ：930338998</div>
        </div>
        <div class="contactus-main-box-right">
          <div class="img-box">
            <div class="img">
              <img class="img1" src="../../assets/gongzhonggao.jpg" alt="" />
            </div>
          </div>
          <div class="text">
            {{ $store.state.staticName.WeChat_Official_Account }}
          </div>
        </div>
      </div>
    </div>
    <Foot1Mobile></Foot1Mobile>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import MyBaiduMap from "@/components/MyBaiduMap";
import Foot1Mobile from "@/components/mobile/FootMobile";

export default {
  name: "ContactUsMobile",
  components: { Foot1Mobile, MyBaiduMap, HeadMobile },
};
</script>

<style lang="scss" scoped>
.img1 {
  width: 100%;
  height: 100%;
}
.contactus {
  background: #fff;

  .contactus-titles {
    width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 29px;

    .contactus-title {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      color: #1850af;
    }
  }
  .map-box {
    margin-top: 12px;
    width: 355px;
    height: 180px;
    background: rgba(0, 0, 0, 0);
    margin-left: 10px;
  }
  .contactus-main-box {
    margin: 0 auto 80px;
    width: 375px;
    display: flex;
    justify-content: space-between;
    //margin-top: 22px;
    //margin-left: 186px;
    //margin-right: 314px;
    .contactus-main-box-left {
      width: 200px;
      display: flex;
      flex-direction: column;

      .msg {
        box-sizing: border-box;
        margin-top: 12px;
        padding-left: 10px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
      .msg1 {
        margin-top: 23px;
      }
    }
    .contactus-main-box-right {
      margin-right: 10px;
      box-sizing: border-box;
      width: 128px;
      .img-box {
        margin-top: 60px;
        width: 128px;
        text-align: center;
        .img {
          margin: 0 auto;
          width: 63px;
          height: 63px;
          background: #fafafa;
          border-radius: 8px;
          border: 4px solid #1850af;
        }
      }
      .text {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #999999;
      }
    }
  }
}
:deep(.mymap div) {
  height: 180px !important;
}
:deep(.mymap) {
  height: 180px !important;
}
</style>
